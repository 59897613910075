// @ts-strict-ignore
import _ from 'lodash';
import { sqItemsApi } from '@/sdk';

export const WORKBOOK_SCHEMA_VERSION = 1;
export const WORKBOOK_STATE_PROPERTY = 'workbookState';

/**
 * Get the current user's workbook state. If there is no state, it returns an empty object.
 *
 * @returns {Promise} A promise that will resolve with state for the current user's workbook.
 */
export function getWorkbook(id) {
  return sqItemsApi
    .getProperty({ id, propertyName: WORKBOOK_STATE_PROPERTY })
    .then(({ data }) => {
      // First-time user that has no workbook state
      return _.isUndefined(data) ? {} : JSON.parse(data.value).state;
    })
    .catch(() => {
      // Our frontend code now adds default workbook state when a workbook is created via workbench, so
      // most of the time this catch will not be hit. We still need the catch though for previously-created
      // workbooks and workbooks created through the REST API.
    });
}

/**
 * Set the workbook state for the current user
 *
 * @param {Object} state - The workbook state object
 * @returns {Promise} A promise that will resolve when the current user's workbook state has been set.
 */
export function setWorkBook(id, state) {
  const workbookData = JSON.stringify({
    version: WORKBOOK_SCHEMA_VERSION,
    state,
  });
  return sqItemsApi
    .setProperty({ value: workbookData }, { id, propertyName: WORKBOOK_STATE_PROPERTY })
    .then(({ data }) => data);
}
