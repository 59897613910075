// @ts-strict-ignore
import { sqWorkbenchStore } from '@/core/core.stores';
import { sqUsersApi, UserInputV1, UserOutputV1 } from '@/sdk';
import { AxiosPromise } from 'axios';
import { WORKBENCH_SCHEMA_VERSION } from '@/workbench/workbench.constants';

/**
 * Get the current user's workbench state. If there is no state, it returns an empty object.
 * @returns {Object} state for the current user's workbench.
 */
export function getWorkbench() {
  if (sqWorkbenchStore.currentUser.workbench) {
    return JSON.parse(sqWorkbenchStore.currentUser.workbench).state;
  }
  return {};
}

/**
 * Set the workbench state for the current user
 * @param {Object} state - The workbench state object
 * @returns {Promise} A promise that will resolve when the current user's workbench state has been set.
 */
export function setWorkbench(state): AxiosPromise<UserOutputV1> {
  const workbenchData = {
    workbench: JSON.stringify({
      version: WORKBENCH_SCHEMA_VERSION,
      state,
    }),
  } as UserInputV1;
  return sqUsersApi.updateUser(workbenchData, {
    id: sqWorkbenchStore.currentUser.id,
  });
}
